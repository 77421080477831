import { useTheme } from "@mui/material";

export default function Experience() {
  const theme = useTheme();

  return (
    <div className="experience" id="experience">
      <h1>Experience</h1>
      <h2>Todo:</h2>
      <p>Get Experience.</p>
    </div>
  );
}
